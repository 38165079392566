/* eslint-disable unicorn/prevent-abbreviations */
import React, { useCallback, useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import { marked } from 'marked';
import { LoadingIndicator } from '../LoadingIndicator/index.tsx';
// import { useResizeObserver } from '@wojtekmaj/react-hooks';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import styles from './document-previewer.module.css';

// const resizeObserverOptions = {};

// const useWidth = (target: any) => {
//   console.log('** useWidth target', target);
//   const [width, setWidth] = useState<number>();

//   useLayoutEffect(() => {
//     setWidth(target?.current?.getBoundingClientRect()?.width);
//   }, [target]);

//   useResizeObserver(target, {}, (entry) => {
//     console.log('** useWidth entry', entry);
//     setWidth(entry?.contentRect?.width);
//   });
//   // if (!target || !target?.current) return;
//   return width;
// };

// Set workerSrc for pdf.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface DocumentPreviewerProps {
  url: string;
  pageNumberProps?: number;
  searchTextProps?: string; // Optional prop to specify a starting page number
  containerWidth: number;
  // setContainerWidth: (width: number) => void;
  // sheetContentRef: Element | null;
}

function escapeRegExp(string: string) {
  return string.replaceAll(/[$()*+.?[\\\]^{|}-]/g, '\\$&'); // Escapes special characters for regex
}

function normalizeText(text: string) {
  // Replace various types of apostrophes and quotation marks with a standard apostrophe
  const standardizedApostrophes = text.replaceAll(/[\u02BB\u02BC\u2018\u2019\u201B]/g, "'");

  // Replace all whitespace (including line breaks) with a single space
  return standardizedApostrophes.replaceAll(/\s+/g, ' ');
}

function highlightPattern(text: string, pattern: string, matchLength: number) {
  // Normalize text to handle line breaks and multiple spaces
  const normalizedText = normalizeText(text);

  // Split the pattern into individual words
  const words = pattern.split(/\s+/);

  // Create a pattern that matches sequences of `matchLength` words from the searchText
  const chunks = [];
  for (let i = 0; i < words.length; i++) {
    if (i + matchLength <= words.length) {
      // Join `matchLength` words at a time and escape for use in regex
      chunks.push(escapeRegExp(words.slice(i, i + matchLength).join(' ')));
    } else {
      // Include the last few words which are fewer than `matchLength`
      chunks.push(escapeRegExp(words.slice(i, words.length).join(' ')));
    }
  }

  // Create a regex to match any of these sequences
  const chunkRegex = new RegExp(chunks.join('|'), 'gi'); // 'gi' for global and case insensitive

  // Replace each occurrence of any sequence in the normalized text with the highlighted version
  const highlightedText = normalizedText.replaceAll(chunkRegex, (matchedChunk) => `<mark>${matchedChunk}</mark>`);
  // const highlightedText = normalizedText.replaceAll(chunkRegex, (matchedChunk) => {
  //   // Split the matched chunk into words and wrap each word with <mark>
  //   return matchedChunk
  //     .split(/\s+/)
  //     .map((word) => `<mark>${word}</mark>`)
  //     .join(' ');
  // });

  return highlightedText;
}

export const DocumentPreviewer = ({
  url,
  pageNumberProps = 1,
  searchTextProps = '',
  containerWidth,
  // setContainerWidth,
  // sheetContentRef,
}: DocumentPreviewerProps) => {
  // console.log('** DocumentPreviewer', url, pageNumberProps);
  const [numPages, setNumPages] = useState<number>(0);
  // const [previewContent, setPreviewContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(pageNumberProps);

  const [searchText, setSearchText] = useState(searchTextProps);

  // eslint-disable-next-line unicorn/no-null
  // const [containerRef, setContainerRef] = useState<Element>();
  // const [containerWidth, setContainerWidth] = useState<number>();
  // useEffect(() => {
  //   console.log('** sheetContentRef', sheetContentRef);
  //   if (sheetContentRef) {
  //     setContainerRef(sheetContentRef);
  //   }
  // }, [sheetContentRef]);
  // const width = useWidth(sheetContentRef || containerRef);

  useEffect(() => {
    if (url && url.endsWith('.md')) {
      setLoading(true);
      fetch(url)
        .then((response) => response.text())
        .finally(() => setLoading(false));
      // .then((text) => {
      //   setPreviewContent(marked.parse(text));
      // })
    } else if (url.endsWith('.pdf')) {
      // Reset to the first page or specified page number when the URL changes
      setPageNumber(pageNumberProps);
      setSearchText(searchTextProps);
    }
  }, [url, pageNumberProps, searchTextProps]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  function changePage(offset: number) {
    setPageNumber((previousPageNumber) => previousPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onItemClick({ pageNumber: itemPageNumber }: { pageNumber: number }) {
    console.log('** onItemClick', itemPageNumber);
    setPageNumber(itemPageNumber);
  }

  // const onResize = useCallback<ResizeObserverCallback>(
  //   (entries) => {
  //     const [entry] = entries;

  //     if (entry) {
  //       setContainerWidth(entry.contentRect.width);
  //     }
  //   },
  //   [setContainerWidth],
  // );

  // useResizeObserver(containerRef, resizeObserverOptions, onResize);

  // const handleResize = (entry: any) => {
  //   console.log('**handleResize');
  //   const { width } = entry.contentRect;
  //   console.log('**handleResize width', width);
  //   const margin = 20; // Adjust this margin as necessary
  //   setContainerWidth(width - margin);
  // };
  // console.log('** width', width);
  console.log('** containerWidth', containerWidth);
  // console.log('** sheetContentRef', sheetContentRef);
  // eslint-disable-next-line unicorn/no-null
  // useResizeObserver(sheetContentRef, resizeObserverOptions, handleResize);

  const textRenderer = useCallback((textItem: any) => highlightPattern(textItem.str, searchText, 6), [searchText]);

  // const renderPDFContent = () => (
  //   <div className="current-document">
  //     <Document
  //       file={url}
  //       onLoadSuccess={onDocumentLoadSuccess}
  //       loading={<LoadingIndicator label="Loading PDF..." />}
  //       onItemClick={onItemClick}
  //     >
  //       {/* <Outline onItemClick={onItemClick} /> */}
  //       <Page
  //         pageNumber={pageNumber}
  //         renderAnnotationLayer={false}
  //         customTextRenderer={textRenderer}
  //         // width={width}
  //         width={containerWidth}
  //       />
  //     </Document>
  //     <div className={styles.pageControls}>
  //       <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
  //         ‹
  //       </button>
  //       <span>
  //         {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
  //       </span>
  //       <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
  //         ›
  //       </button>
  //     </div>
  //   </div>
  // );

  // const renderMarkdownContent = () => <div dangerouslySetInnerHTML={{ __html: previewContent }} />;

  // const renderContent = () => {
  //   // eslint-disable-next-line unicorn/no-null
  //   if (!url) return null;
  //   return url.endsWith('.pdf') ? renderPDFContent() : renderMarkdownContent();
  // };

  return (
    <>
      {loading && !url.endsWith('.pdf') ? (
        <LoadingIndicator label="Loading..." />
      ) : (
        // <div style={{ overflow: 'auto', height: '100%' }}>{renderContent()}</div>
        // <div style={{ height: '100%' }}>{renderContent()}</div>
        // <div style={{ width: containerWidth }}>{renderContent()}</div>
        <div style={{ height: '100%' }}>
          {' '}
          <div className="current-document">
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<LoadingIndicator label="Loading PDF..." />}
              onItemClick={onItemClick}
            >
              {/* <Outline onItemClick={onItemClick} /> */}
              <Page
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                customTextRenderer={textRenderer}
                // width={width}
                width={containerWidth}
              />
            </Document>
            <div className={styles.pageControls}>
              <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                ‹
              </button>
              <span>
                {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </span>
              <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
                ›
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// import { useState, useEffect } from 'react';
// import { marked } from 'marked';

// import { LoadingIndicator } from '../LoadingIndicator/index.tsx';

// interface DocumentPreviewerProps {
//   url: string;
// }

// export const DocumentPreviewer = ({ url }: DocumentPreviewerProps) => {
//   const [previewContent, setPreviewContent] = useState('');
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const retrieveMarkdown = () => {
//       if (url && url.endsWith('.md')) {
//         setLoading(true);
//         fetch(url)
//           .then((response) => response.text())
//           .then((text) => {
//             setPreviewContent(marked.parse(text));
//           })
//           .finally(() => {
//             setLoading(false);
//           });
//       }
//     };

//     retrieveMarkdown();
//   }, [url]);

//   const renderContent = () => {
//     if (!url) return undefined;

//     return previewContent ? (
//       <div dangerouslySetInnerHTML={{ __html: previewContent }} />
//     ) : (
//       <iframe title="Preview" src={url} width="100%" height="850px" sandbox="allow-scripts allow-same-origin" />
//     );
//   };

//   return <>{loading ? <LoadingIndicator label="Loading..." /> : renderContent()}</>;
// };

/* eslint-disable unicorn/prefer-string-replace-all */
import type { ChatThreadEntry, Citation, ProcessTextReturn } from '../../types.ts';

// Util functions to process text from response, clean it up and style it
// We keep it in this util file because we may not need it once we introduce
// a new response format with TypeChat or a similar component

// Let's give the response a type so we can use it in the component

// export function processText(inputText: string, arrays: Array<Array<string> | Array<Citation>>): ProcessTextReturn {
//   console.log('**processText', inputText);
//   // Keeping all the regex at this level so they can be easily changed or removed
//   const nextQuestionMatch = `Next questions:|<<([^>]+)>>`;
//   const findCitations = /\[(.*?)]/g;
//   const findFollowingSteps = /:(.*?)(?:Follow-up questions:|Next questions:|<<|$)/s;
//   const findNextQuestions = /Next Questions:(.*?)$/s;
//   // const findQuestionsbyDoubleArrow = /<<([^<>]+)>>/g;
//   const findQuestionsbyDoubleArrow = /<<([^>]+)>>/g;
//   const findNumberedItems = /^\d+\.\s/;

//   // Find and process citations
//   // const citation: NonNullable<unknown> = {};
//   // let citations: Citation[] = [];
//   // let referenceCounter = 1;
//   // // eslint-disable-next-line unicorn/prefer-string-replace-all
//   // let replacedText = inputText.replace(findCitations, (_match, capture) => {
//   //   const citationText = capture.trim();
//   //   if (!(citation as Record<string, number>)[citationText]) {
//   //     (citation as Record<string, number>)[citationText] = referenceCounter++;
//   //   }
//   //   return `<sup className="citation">${(citation as Record<string, number>)[citationText]}</sup>`;
//   // });
//   // citations = Object.keys(citation).map((text, index) => ({
//   //   ref: index + 1,
//   //   text,
//   // }));
//   // Process citations
//   const citations: Citation[] = [];
//   // eslint-disable-next-line unicorn/prefer-string-replace-all
//   // let replacedText = inputText.replace(findCitations, (_match, capture, index) => {
//   //   citations.push({ ref: index + 1, text: capture.trim() });
//   //   return `<sup class="citation">${index + 1}</sup>`;
//   // });
//   let replacedText = inputText.replace(findCitations, (match, capture) => {
//     const index = citations.length + 1; // Calculate citation index based on array length
//     citations.push({ ref: index, text: capture.trim() });
//     return `<sup class="citation">${index}</sup>`; // Replace with a superscript for the citation reference
//   });
//   arrays[0] = citations;

//   // Because the format for followup questions is inconsistent
//   // and sometimes it includes a Next Questions prefix, we need  do some extra work
//   const hasNextQuestions = replacedText.includes(nextQuestionMatch);
//   // Find and store 'follow this steps' portion of the response
//   // considering the fact that sometimes the 'next questions' indicator is present
//   // and sometimes it's not
//   const followingStepsMatch = replacedText.match(findFollowingSteps);
//   const followingStepsText = followingStepsMatch ? followingStepsMatch[1].trim() : '';
//   const followingSteps = followingStepsText.split('\n').filter(Boolean);
//   const cleanFollowingSteps = followingSteps.map((item) => {
//     return item.replace(findNumberedItems, '');
//   });
//   arrays[1] = cleanFollowingSteps;

//   // Determine which regex to use, depending if the indicator is present
//   // const nextRegex = hasNextQuestions ? findNextQuestions : findQuestionsbyDoubleArrow;
//   // const nextQuestionsMatch = replacedText.match(nextRegex) ?? [];
//   // let nextQuestions: string[] = [];
//   // nextQuestions = cleanUpFollowUp([...(nextQuestionsMatch as string[])]);
//   const followUpQuestions: string[] = [];
//   // let match;
//   // while ((match = findQuestionsbyDoubleArrow.exec(replacedText)) !== null) {
//   //   followUpQuestions.push(match[1].trim());
//   // }
//   replacedText = replacedText.replace(findQuestionsbyDoubleArrow, (match, capture) => {
//     followUpQuestions.push(capture.trim());
//     return ''; // Optionally remove the original follow-up questions from the text if desired
//   });

//   // Remove the 'steps', 'citation' and 'next questions' portions of the response
//   // from the response answer
//   const stepsIndex = replacedText.indexOf('s:');
//   // eslint-disable-next-line unicorn/no-negated-condition, unicorn/prefer-string-slice
//   replacedText = stepsIndex !== -1 ? inputText.substring(0, stepsIndex + 6) : inputText;

//   // arrays[2] = nextQuestions;
//   arrays[2] = followUpQuestions;
//   return { replacedText, arrays };
// }
export function processText(inputText: string, arrays: Array<Array<string> | Array<Citation>>): ProcessTextReturn {
  console.log('**processText', inputText);

  // Regex definitions remain the same
  const findCitations = /\[(.*?)]/g;
  // const findFollowingSteps = /:(.*?)(?:Follow-up questions:|Next questions:|<<|$)/s;
  // const findNumberedItems = /^\d+\.\s/;
  const findQuestionsByDoubleArrow = /<<([^>]+)>>/g;

  // Arrays to hold processed data
  const citations: Citation[] = [];
  const followUpQuestions: string[] = [];

  // Process citations
  let replacedText = inputText.replace(findCitations, (_, capture) => {
    const index = citations.length + 1;
    citations.push({ ref: index, text: capture });
    return `<sup class="citation">${index}</sup>`;
  });

  // Find and store 'follow this steps' portion of the response
  // considering the fact that sometimes the 'next questions' indicator is present
  // and sometimes it's not
  // const followingStepsMatch = replacedText.match(findFollowingSteps);
  // const followingStepsText = followingStepsMatch ? followingStepsMatch[1].trim() : '';
  // const followingSteps = followingStepsText.split('\n').filter(Boolean);
  // const cleanFollowingSteps = followingSteps.map((item) => {
  //   return item.replace(findNumberedItems, '');
  // });

  // Extract and clean follow-up questions
  replacedText = replacedText.replace(findQuestionsByDoubleArrow, (_, capture) => {
    followUpQuestions.push(capture.trim());
    return ''; // Remove from the main text
  });

  // Handle numbered lists directly in the replacedText since they should already be correctly formatted.
  // If you have specific logic to further process or highlight these, add here. Otherwise, they remain as is.

  // Assign to provided arrays. Steps are directly part of replacedText in this case.
  arrays[0] = citations; // Array of citations
  arrays[1] = []; // If steps need separate handling, adjust this logic accordingly
  // arrays[1] = cleanFollowingSteps;
  arrays[2] = followUpQuestions; // Array of cleaned-up follow-up questions

  // The replacedText will include the numbered list as originally formatted.
  return { replacedText, arrays };
}

// Clean up responses with << and >>
export function cleanUpFollowUp(followUpList: string[]): string[] {
  if (followUpList && followUpList.length > 0 && followUpList[0].startsWith('<<')) {
    followUpList = followUpList.map((followUp) => followUp.replace('<<', '').replace('>>', ''));
  }
  return followUpList;
}

// Get the current timestamp to display with the chat message
export function getTimestamp() {
  return new Date().toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

export function chatEntryToString(entry: ChatThreadEntry) {
  const message = entry.text
    .map((textEntry) => textEntry.value + '\n\n' + textEntry.followingSteps?.map((s, i) => `${i + 1}.` + s).join('\n'))
    .join('\n\n')
    .replaceAll(/<sup[^>]*>(.*?)<\/sup>/g, ''); // remove the <sup> tags from the message

  return message;
}

// Creates a new chat message error
export class ChatResponseError extends Error {
  code?: number;

  constructor(message: string, code?: number) {
    super(message);
    this.code = code;
  }
}

export function newListWithEntryAtIndex<T>(list: T[], index: number, entry: T) {
  return [...list.slice(0, index), entry, ...list.slice(index + 1)];
}

// "The FedRAMP authorization process consists of several phases, including the agency final review and authorization to operate (ATO) phase [Agency_Authorization_Playbook.pdf]. Once the agency review team completes their review of the authorization package, the Agency Authorizing Official (AO) issues an ATO for the Cloud Service Provider (CSP) [Agency_Authorization_Playbook.pdf]. The FedRAMP Program Management Office (PMO) also conducts a review of the authorization package after the ATO is issued [Agency_Authorization_Playbook.pdf]. The PMO review helps determine the overall compliance and readiness of the CSP [Agency_Authorization_Playbook.pdf].\n\nFollow-up questions:\n1. <<What happens during the agency final review phase?>>\n2. <<Who issues the ATO for the CSP?>>\n3. <<What is the purpose of the PMO review?>>"

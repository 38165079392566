import { useState, useEffect, useCallback } from 'react';
import { getAPIResponse } from '../core/http/index.js';
import { parseStreamedMessages } from '../core/parser/index.js';
import { type ChatResponseError, processText, getTimestamp } from '../utils/index.js';
import { globalConfig } from '../config/global-config.ts';
import type { BotResponse, Citation, ChatThreadEntry, ChatRequestOptions, ChatHttpOptions } from '../../types.ts';

const useChatController = (
  apiUrl: string,
  useStream: boolean = false,
  processingMessage: ChatThreadEntry | undefined,
  setProcessingMessage: (message: ChatThreadEntry | undefined) => void,
) => {
  console.log('useChatController', 'apiUrl:', apiUrl, 'useStream:', useStream);
  // const [chatThread, setChatThread] = useState<ChatThreadEntry[]>([]);
  const [isProcessingResponse, setIsProcessingResponse] = useState<boolean>(false);
  // const [isAwaitingResponse, setIsAwaitingResponse] = useState<boolean>(false);
  // const [generatingAnswer, setGeneratingAnswer] = useState<boolean>(false);
  // const [processingMessage, setProcessingMessage] = useState<ChatThreadEntry | undefined>();
  const [abortController, setAbortController] = useState(new AbortController());

  // Effect for abort controller cleanup
  useEffect(() => {
    console.log('useChatController useEffect');
    const controller = new AbortController();
    setAbortController(controller);
    return () => controller.abort();
  }, [apiUrl, useStream]);

  // Clear function adjusted for functional component state management
  const clearChat = useCallback(() => {
    console.log('useChatController clearChat');

    // setIsAwaitingResponse(false);
    setIsProcessingResponse(false);
    // setGeneratingAnswer(false);
    // Abort any ongoing fetch request
    abortController.abort();
  }, [abortController]);

  const processResponse = async (
    response: string | BotResponse,
    isUserMessage: boolean = false,
    useStream: boolean = false,
  ) => {
    console.log('useChatController processResponse', response, isUserMessage, useStream);

    const citations: Citation[] = [];
    const followingSteps: string[] = [];
    const followupQuestions: string[] = [];
    const timestamp = getTimestamp();
    let thoughts: string | undefined;
    let dataPoints: string[] | undefined;

    const updateChatWithMessageOrChunk = async (message: string | BotResponse, chunked: boolean) => {
      console.log('useChatController updateChatWithMessageOrChunk', message, chunked);

      setProcessingMessage({
        id: crypto.randomUUID(),
        text: [
          {
            value: chunked ? '' : (message as string),
            followingSteps,
          },
        ],
        followupQuestions,
        citations: [...new Set(citations)],
        timestamp: timestamp,
        isUserMessage,
        thoughts,
        dataPoints,
      });

      if (chunked && processingMessage) {
        console.log('useChatController chunked && processingMessage');

        setIsProcessingResponse(true);
        // this._abortController = new AbortController();

        await parseStreamedMessages({
          chatEntry: processingMessage,
          signal: abortController.signal,
          apiResponseBody: (message as unknown as Response).body,
          onChunkRead: (updated) => {
            setProcessingMessage(updated);
          },
          onCancel: () => {
            clearChat();
          },
        });

        // processing done.
        clearChat();
      }
    };

    // Check if message is a bot message to process citations and follow-up questions

    if (isUserMessage || typeof response === 'string') {
      console.log('useChatController isUserMessage || typeof response === string');

      await updateChatWithMessageOrChunk(response, false);
    } else if (useStream) {
      console.log('useChatController useStream');

      await updateChatWithMessageOrChunk(response, true);
    } else {
      console.log('useChatController ELSE');

      // non-streamed response
      const generatedResponse = (response as BotResponse).choices[0].message;
      const processedText = processText(generatedResponse.content, [citations, followingSteps, followupQuestions]);
      const messageToUpdate = processedText.replacedText;
      // Push all lists coming from processText to the corresponding arrays
      citations.push(...(processedText.arrays[0] as unknown as Citation[]));
      followingSteps.push(...(processedText.arrays[1] as string[]));
      followupQuestions.push(...(processedText.arrays[2] as string[]));
      thoughts = generatedResponse.context?.thoughts ?? '';
      // https://github.com/Azure-Samples/azure-search-openai-javascript/pull/170/files
      // dataPoints = generatedResponse.context?.data_points ?? [];
      dataPoints = generatedResponse.context?.data_points?.text ?? [];

      await updateChatWithMessageOrChunk(messageToUpdate, false);
    }
  };

  const generateAnswer = async (requestOptions: ChatRequestOptions, httpOptions: ChatHttpOptions) => {
    console.log('useChatController generateAnswer');

    const { question } = requestOptions;

    if (!question) return;

    setIsProcessingResponse(true);

    try {
      console.log('useChatController generateAnswer try');

      if (requestOptions.type === 'chat') {
        await processResponse(question, true, false);
      }

      // setIsAwaitingResponse(true);
      // eslint-disable-next-line unicorn/no-useless-undefined
      setProcessingMessage(undefined);

      const response = (await getAPIResponse(requestOptions, httpOptions)) as BotResponse;
      // setIsAwaitingResponse(false);

      await processResponse(response, false, httpOptions.stream);
    } catch (_error: any) {
      console.log('useChatController generateAnswer catch', _error);

      const error = _error as ChatResponseError;
      console.error('Error generating answer:', error);
      const chatError = {
        message: error?.code === 400 ? globalConfig.INVALID_REQUEST_ERROR : globalConfig.API_ERROR_MESSAGE,
      };

      if (!processingMessage) {
        console.log('useChatController generateAnswer catch !processingMessage');
        // add a empty message to the chat thread to display the error
        await processResponse('', false, false);
      }

      if (processingMessage) {
        console.log('useChatController generateAnswer catch processingMessage');

        setProcessingMessage({
          ...processingMessage,
          error: chatError,
        });
      }
    } finally {
      console.log('useChatController generateAnswer finally');
      clearChat();
    }
  };

  const cancelRequest = useCallback(() => {
    abortController.abort();
  }, [abortController]);

  return {
    clearChat,
    isProcessingResponse,
    // processingMessage,
    generateAnswer,
    cancelRequest,
  };
};

export default useChatController;

import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import FedOwl from '../../assets/FedOwl.tsx';

// import github from '../../assets/github.svg';

// import styles from './Layout.module.css';

const Layout = () => {
  // const providers = ['github', 'aad'];
  // const redirect = window.location.pathname;
  // const [userInfo, setUserInfo] = useState<any>();

  // useEffect(() => {
  //   (async () => {
  //     setUserInfo(await getUserInfo());
  //   })();
  // }, []);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  // async function getUserInfo() {
  //   try {
  //     const response = await fetch('/.auth/me');
  //     const payload = await response.json();
  //     console.log('***********getUserInfo', payload);
  //     const { clientPrincipal } = payload;
  //     return clientPrincipal;
  //   } catch (error) {
  //     console.log('No profile could be found', error);
  //     console.error('No profile could be found');
  //     return undefined;
  //   }
  // }

  return (
    <div className="h-full bg-none">
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
        <Link to="/" className="flex items-center gap-2 text-lg font-semibold md:text-base">
          <FedOwl className="h-8 md:h-10 w-auto dark:fill-white" />
          {/* <Link to="/" className={styles.headerTitleContainer}> */}
          {/* <h3 className={styles.headerTitle}>FedOwl = ChatGPT + FedRAMP</h3> */}
        </Link>
        <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          {/* <ul className={styles.headerNavList}>
              <li> */}
          {/* <NavLink
            to="/"
            // className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
            className={({ isActive }) =>
              `${isActive ? 'text-foreground' : 'text-muted-foreground'} transition-colors hover:text-foreground`
            }
          >
            Chat
          </NavLink> */}
          {/* </li>
              <li className={styles.headerNavLeftMargin}> */}
          {/* <NavLink
            to="/qa"
            className={({ isActive }) =>
              `${isActive ? 'text-foreground' : 'text-muted-foreground'} transition-colors hover:text-foreground`
            }
          >
            Ask a question
          </NavLink> */}
          {/* </li>
              <li className={styles.headerNavLeftMargin}> */}
          {/* <NavLink
            to="/login"
            className={({ isActive }) =>
              `${isActive ? 'text-foreground' : 'text-muted-foreground'} transition-colors hover:text-foreground`
            }
          >
            Login
          </NavLink> */}
          {/* </li>
              <li className={styles.headerNavLeftMargin}> */}
          {/* <NavLink
            to="/secret"
            className={({ isActive }) =>
              `${isActive ? 'text-foreground' : 'text-muted-foreground'} transition-colors hover:text-foreground`
            }
          >
            secret
          </NavLink> */}
          {/* </li>
            </ul> */}
        </nav>
        {/* <h4 className={styles.headerRightText}>
          {!userInfo &&
            providers.map((provider) => (
              <a key={provider} href={`/.auth/login/${provider}?post_login_redirect_uri=${redirect}`}>
                {provider}
              </a>
            ))}
          {userInfo && (
            <>
              <p>{userInfo && userInfo.userDetails}</p>
              <p>{userInfo && userInfo.identityProvider}</p>
              <a href={`/.auth/logout?post_logout_redirect_uri=${redirect}`}>Logout</a>
            </>
          )}
        </h4> */}
      </header>
      <Outlet />
    </div>
  );
  // {/* <h4 className={styles.headerRightText}>Unnamed FedRAMP Search</h4> */}
  // {/* </div> */}
};

export default Layout;

/* eslint-disable unicorn/prevent-abbreviations */
import { CitationList } from '../CitationList/index.tsx';
import { DocumentPreviewer } from '../DocumentPreviewer/index.tsx';
// import { TeaserListComponent } from '../TeaserListComponent/index.tsx';
import { chatHttpOptions, globalConfig } from '../../config/global-config.js';
import type { ChatThreadEntry, Citation, TabOptions } from '../../../types.ts';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs.jsx';
import { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader } from '@/components/ui/card.tsx';

interface ChatEntryTabContentProps {
  selectedChatEntry?: ChatThreadEntry;
  selectedCitation?: Citation;
  onCitationClick: (citation: Citation, chatThreadEntry?: ChatThreadEntry) => void;
  selectedTabId: TabOptions;
  setSelectedTabId: (tabId: TabOptions) => void;
  apiUrl?: string;
  containerWidth: number;
  // setContainerWidth: (width: number) => void;
  // sheetContentRef: Element | null;
}

export const ChatEntryTabContent = ({
  selectedChatEntry,
  selectedCitation,
  onCitationClick,
  selectedTabId,
  setSelectedTabId,
  apiUrl = chatHttpOptions.url,
  containerWidth,
  // setContainerWidth,
  // sheetContentRef,
}: ChatEntryTabContentProps) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [documentName, setDocumentName] = useState(selectedCitation?.text || '');
  const [searchText, setSearchText] = useState('');

  const tabs: { id: TabOptions; label: string }[] = [
    // { id: 'tab-thought-process', label: 'Thought Process' },
    { id: 'tab-support-context', label: 'Support Context' },
    { id: 'tab-citations', label: 'Citations' },
  ];

  useEffect(() => {
    if (selectedCitation?.text) {
      const parts = selectedCitation.text.split(':');
      if (parts.length === 2) {
        // Assuming the format is always "DocumentName:PageNumber"
        const docName = parts[0];
        const page = parts[1];

        // Set state for document name and page number
        setDocumentName(docName);
        setPageNumber(Number(page));
      }
    }
  }, [documentName, selectedCitation]);

  useEffect(() => {
    if (selectedCitation && selectedChatEntry && selectedChatEntry.dataPoints) {
      // Extract the PDF name and page number from selectedCitation
      const citationPattern = /(.+\.pdf):(\d+)/;
      const match = selectedCitation.text.match(citationPattern);

      if (match) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, citationPdf, citationPage] = match;

        // Find the matching entry in selectedChatEntry.dataPoints
        const dataPointPattern = new RegExp(`${citationPdf}:${citationPage}:`);
        const matchingEntry = selectedChatEntry.dataPoints.find((entry) => dataPointPattern.test(entry));
        console.log('** matchingEntry', matchingEntry);
        if (matchingEntry) {
          // Extract and set the relevant text from the matching entry
          const [, content] = matchingEntry.split(dataPointPattern);
          setSearchText(content.trim());
        }
      }
    }
  }, [selectedCitation, selectedChatEntry, setSearchText]);

  // const handleCitationClick = (citation: Citation, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   event.preventDefault();
  //   onCitationClick && onCitationClick(citation);
  // };

  console.log('** ChatEntryTabContent', selectedChatEntry);
  console.log('** ChatEntryTabContent selectedCitation', selectedCitation);
  console.log('** ChatEntryTabContent searchText', searchText);

  return (
    // <div className="flex flex-col h-full">
    <Tabs
      value={selectedTabId}
      onValueChange={(value) => setSelectedTabId(value as TabOptions)}
      orientation="vertical"
      className="flex flex-col h-full"
    >
      <TabsList className="mx-auto">
        {tabs.map((tab) => (
          <TabsTrigger key={tab.id} value={tab.id}>
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {/* <TabsContent value="tab-thought-process" className="overflow-y-auto">
        {selectedChatEntry?.thoughts && (
          <div slot="tab-thought-process">
            <p className={'px-4 mb-8'} dangerouslySetInnerHTML={{ __html: selectedChatEntry.thoughts }} />
            {/* <p>{selectedChatEntry.thoughts}</p> 
          </div>
        )}
      </TabsContent> */}
      <TabsContent value="tab-support-context" className="overflow-y-auto">
        {selectedChatEntry?.dataPoints && (
          <div slot="tab-support-context" className="flex flex-col">
            {selectedChatEntry.dataPoints.map((d, index) => {
              // Assuming the format is always "DocumentName:PageNumber"
              const parts = d.split(':');
              return (
                <Card key={index} className="m-2 bg-muted">
                  {parts.length > 2 && (
                    <CardHeader>
                      {parts[0]} p{parts[1]}
                    </CardHeader>
                  )}
                  <CardContent>{d}</CardContent>
                </Card>
              );
            })}
          </div>
        )}
      </TabsContent>
      <TabsContent value="tab-citations" className="overflow-y-auto">
        {selectedChatEntry?.citations && (
          <div slot="tab-citations">
            <CitationList
              citations={selectedChatEntry.citations}
              label={globalConfig.CITATIONS_LABEL}
              selectedCitation={selectedCitation} // Adjust based on your state management
              onCitationClick={onCitationClick}
              onTab
            />
            {selectedCitation && (
              <DocumentPreviewer
                url={`${apiUrl}/content/${documentName}`}
                pageNumberProps={pageNumber}
                searchTextProps={searchText}
                containerWidth={containerWidth}
                // setContainerWidth={setContainerWidth}
                // sheetContentRef={sheetContentRef}
              />
            )}
          </div>
        )}
      </TabsContent>
    </Tabs>
    // </div>
  );
};

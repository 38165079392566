import { useState, useEffect, useCallback } from 'react';
import { MAX_CHAT_HISTORY } from '../config/global-config.ts';
import type { ChatThreadEntry } from '../../types.ts';

const CHATHISTORY_ID = 'ms-azoaicc:history';

const useChatHistory = () => {
  const [chatHistory, setChatHistory] = useState<ChatThreadEntry[]>([]);
  const [showChatHistory, setShowChatHistory] = useState(false);

  useEffect(() => {
    const storedHistory = localStorage.getItem(CHATHISTORY_ID);
    if (storedHistory) {
      const history = JSON.parse(atob(storedHistory));
      const lastUserMessagesIndexes = history
        .map((entry: ChatThreadEntry, index: number) => (entry.isUserMessage ? index : undefined))
        .filter((index: number) => index !== undefined)
        .slice(-MAX_CHAT_HISTORY);

      const trimmedHistory = lastUserMessagesIndexes.length === 0 ? history : history.slice(lastUserMessagesIndexes[0]);
      setChatHistory(trimmedHistory);
    }
  }, []);

  const saveChatHistory = useCallback(
    (currentChat: ChatThreadEntry[]) => {
      const newChatHistory: ChatThreadEntry[] = [...chatHistory, ...currentChat];
      localStorage.setItem(CHATHISTORY_ID, btoa(JSON.stringify(newChatHistory)));
    },
    [chatHistory],
  );

  const toggleChatHistory = useCallback(() => {
    setShowChatHistory((previousShow) => !previousShow);
  }, []);

  return {
    chatHistory,
    showChatHistory,
    toggleChatHistory,
    saveChatHistory,
  };
};

export default useChatHistory;

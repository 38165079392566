import { useState, useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';

import { CitationList } from '../CitationList/index.js';
import { ChatActionButton } from '../ChatActionButton/index.js';

import { globalConfig } from '../../config/global-config.js';
import type { ChatMessageText, ChatThreadEntry, Citation } from '../../../types.js';
import { chatEntryToString } from '../../utils/index.js';

import { ClipboardCopy, Check, Lightbulb } from 'lucide-react';
import { Separator } from '@/components/ui/separator.tsx';
import { Card, CardContent, CardDescription, CardHeader } from '@/components/ui/card.tsx';

import {
  // Sheet,
  // SheetContent,
  // SheetDescription,
  // SheetHeader,
  // SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet.jsx';

import styles from './chat-thread-component.module.css';

interface ChatThreadComponentProps {
  chatThread: ChatThreadEntry[];
  // isShowingThoughtProcess: boolean;
  isDisabled: boolean;
  isProcessingResponse: boolean;
  selectedCitation?: Citation;
  handleChatEntryActionButtonClick: (entry: ChatThreadEntry) => void;
  onCitationClick: (citation: Citation, entry: ChatThreadEntry) => void;
  onFollowupQuestionClick: (question: string) => void;
}

export const ChatThreadComponent = ({
  chatThread,
  // isShowingThoughtProcess,
  isDisabled,
  isProcessingResponse,
  selectedCitation,
  handleChatEntryActionButtonClick,
  onFollowupQuestionClick,
  onCitationClick,
}: ChatThreadComponentProps) => {
  console.log('** ChatThreadComponent', chatThread);
  const [isResponseCopied, setIsResponseCopied] = useState(false);
  const chatFooterReference = useRef<HTMLDivElement | null>(null);
  const debounceTimeoutReference = useRef<number | null>(null);

  const copyResponseToClipboard = (entry: ChatThreadEntry) => {
    const response = chatEntryToString(entry);

    navigator.clipboard.writeText(response);
    setIsResponseCopied(true);
    setTimeout(() => setIsResponseCopied(false), 2000); // Reset copied state after 2 seconds
  };

  // debounce dispatching must-scroll event
  const debounceScrollIntoView = () => {
    if (debounceTimeoutReference.current) {
      clearTimeout(debounceTimeoutReference.current);
    }
    debounceTimeoutReference.current = setTimeout(() => {
      if (chatFooterReference.current) {
        chatFooterReference.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 500);
  };

  useEffect(() => {
    if (isProcessingResponse) {
      debounceScrollIntoView();
    }

    // Cleanup timeout on component unmount to avoid memory leaks
    return () => {
      if (debounceTimeoutReference.current) {
        clearTimeout(debounceTimeoutReference.current);
      }
    };
  }, [isProcessingResponse]);

  const renderTextEntry = (textEntry: ChatMessageText) => {
    console.log('** ChatThreadComponent renderTextEntry', textEntry);
    // Sanitize the HTML content
    // const sanitizedValue = DOMPurify.sanitize(textEntry.value);
    // const sanitizedValue = textEntry.value
    //   .split('\n')
    //   .map((line) => `<p>${line}</p>`)
    //   .join('');
    const sanitizedValue = DOMPurify.sanitize(
      textEntry.value
        .split('\n')
        .map((line) => `<p>${line}</p>`)
        .join(''),
    );

    return (
      <div className="chat__txt--entry-container">
        {/* Directly rendering sanitized HTML content */}
        <p className={'px-4'} dangerouslySetInnerHTML={{ __html: sanitizedValue }} />

        {/* Render steps if they exist */}
        {textEntry.followingSteps && textEntry.followingSteps.length > 0 && (
          <ol className={'my-2 px-4'}>
            {textEntry.followingSteps.map((step, index) => (
              // Assuming steps are safe or also sanitized as needed
              <li key={index} className={'py-1'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(step) }} />
            ))}
          </ol>
        )}
        {isProcessingResponse ?? debounceScrollIntoView()}
      </div>
    );
  };

  return (
    <div className="chat-thread-component">
      <ul className="flex flex-col gap-2" aria-live="assertive">
        {chatThread.map((entry, index) => (
          <li
            key={index}
            className={`flex flex-col h-auto max-w-[var(--width-wide)] min-w-[var(--width-base)] md:max-w-[55%] md:min-w-[var(--width-narrow)] ${entry.isUserMessage ? `self-end` : ''}`}
          >
            <Card className={`${entry.isUserMessage ? 'text-primary-foreground bg-primary pt-6' : `bg-muted`}`}>
              {/* <div
              className={`relative mt-2 mb-0 break-words rounded-lg shadow-md border ${entry.isUserMessage ? `bg-muted` : 'text-primary-foreground bg-primary'}`}
            > */}
              {!entry.isUserMessage && (
                <CardHeader>
                  <CardDescription className="flex flex-row justify-between items-center w-full">
                    <span className="text-sm text-muted-foreground">
                      {entry.timestamp}, {entry.isUserMessage ? 'You' : globalConfig.USER_IS_BOT}
                    </span>
                    {!entry.isUserMessage && (
                      <div>
                        <SheetTrigger asChild>
                          <ChatActionButton
                            SvgIcon={Lightbulb}
                            // isDisabled={isShowingThoughtProcess}
                            actionId={'chat-show-thought-process'}
                            tooltip={globalConfig.SHOW_THOUGH_PROCESS_BUTTON_LABEL_TEXT}
                            onClick={() => handleChatEntryActionButtonClick(entry)}
                            size={1}
                          />
                        </SheetTrigger>
                        <ChatActionButton
                          SvgIcon={isResponseCopied ? Check : ClipboardCopy}
                          isDisabled={isDisabled}
                          actionId="copy-response"
                          tooltip={
                            isResponseCopied
                              ? globalConfig.COPIED_SUCCESSFULLY_MESSAGE
                              : globalConfig.COPY_RESPONSE_BUTTON_LABEL_TEXT
                          }
                          onClick={() => copyResponseToClipboard(entry)}
                          size={1}
                        />
                      </div>
                    )}
                  </CardDescription>
                </CardHeader>
              )}
              <CardContent>
                {/* Render Text Entry */}
                {entry.text.map((textEntry) => renderTextEntry(textEntry))}

                {/* Render Citations */}
                {entry.citations && entry.citations.length > 0 && (
                  <>
                    <CitationList
                      citations={entry.citations}
                      label="Citations"
                      selectedCitation={selectedCitation}
                      onCitationClick={(citation) => onCitationClick(citation, entry)}
                    />
                    <Separator className="my-2" />
                  </>
                )}

                {/* Render followupQuestions */}
                {entry.followupQuestions && entry.followupQuestions.length > 0 && (
                  <div className="flex flex-col px-4">
                    <p className="text-sm text-muted-foreground">Follow up questions</p>
                    <div className="flex flex-col space-y-2">
                      {entry.followupQuestions.map((question, qIndex) => (
                        <button
                          data-testid="followUpQuestion"
                          onClick={() => onFollowupQuestionClick(question)}
                          className="cursor-pointer p-2 hover:bg-gray-200 rounded"
                          key={qIndex}
                        >
                          {question}
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                {/* Render Error */}
                {entry.error && (
                  <p
                    className={`relative mt-2 mb-0 break-words rounded-lg shadow-md border border-red-500 text-red-500 bg-red-100`}
                  >
                    {entry.error.message}
                  </p>
                )}
                {/* </div> */}
              </CardContent>
            </Card>
            {/* <div className="flex flex-row justify-between items-top w-full"> */}
            {entry.isUserMessage && (
              <div>
                <span className="text-sm text-muted-foreground">{entry.timestamp}</span>,
                <span className="text-sm text-muted-foreground">
                  {entry.isUserMessage ? 'You' : globalConfig.USER_IS_BOT}
                </span>
              </div>
            )}
            {/*  {!entry.isUserMessage && (
                <div>
                  <ChatActionButton
                    SvgIcon={Lightbulb}
                    isDisabled={isShowingThoughtProcess}
                    actionId={'chat-show-thought-process'}
                    tooltip={globalConfig.SHOW_THOUGH_PROCESS_BUTTON_LABEL_TEXT}
                    onClick={() => handleChatEntryActionButtonClick(entry)}
                    size={1}
                  />
                  <ChatActionButton
                    SvgIcon={isResponseCopied ? Check : ClipboardCopy}
                    isDisabled={isDisabled}
                    actionId="copy-response"
                    tooltip={
                      isResponseCopied
                        ? globalConfig.COPIED_SUCCESSFULLY_MESSAGE
                        : globalConfig.COPY_RESPONSE_BUTTON_LABEL_TEXT
                    }
                    onClick={() => copyResponseToClipboard(entry)}
                    size={1}
                  />
                </div>
              )}
            </div> */}
          </li>
        ))}
      </ul>
      <div ref={chatFooterReference} className={styles.chatFooter} id="chat-list-footer">
        {/* Footer for auto-scrolling */}
      </div>
    </div>
  );
};

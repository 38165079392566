import SpinnerIcon from '../../assets/SpinnerIcon.tsx';
import styles from './loading-indicator.module.css';

export const LoadingIndicator = ({ label }: { label: string }) => {
  return (
    <p className={styles.loadingIndicator} aria-label={label} data-testid="loading-indicator">
      <SpinnerIcon />
      <span>{label}</span>
    </p>
  );
};

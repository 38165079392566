import Owl from '../../assets/Owl.tsx';

interface ChatStageProps {
  pageTitle: string;
}

export const ChatStage = ({ pageTitle }: ChatStageProps) => {
  return (
    <header className="flex flex-row justify-center items-center mb-8">
      <Owl className="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 lg:h-16 lg:w-16 dark:fill-white fill-[#373386]" />
      <h1 className="text-3xl font-bold text-[#373386] dark:text-white sm:text-4xl md:text-5xl lg:text-6xl">
        {pageTitle}
      </h1>
    </header>
  );
};

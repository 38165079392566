import React from 'react';
import { Button } from '@/components/ui/button.tsx';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/components/ui/tooltip.tsx';

export interface ChatActionButtonProps {
  SvgIcon: React.ElementType;
  isDisabled?: boolean;
  actionId: string;
  tooltip?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: number;
}

export const ChatActionButton = ({
  // label,
  SvgIcon,
  isDisabled = false,
  actionId = '',
  tooltip,
  onClick,
  size = 8,
}: ChatActionButtonProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            data-testid={actionId}
            disabled={isDisabled}
            onClick={onClick}
            className="cursor-pointer"
          >
            <SvgIcon className={`h-${size} w-${size}`} />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{tooltip}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

import React, { useEffect, useState } from 'react';
import { Badge } from '@/components/ui/badge.jsx';

interface TeaserListProps {
  heading?: string;
  onTeaserClick: (question: string) => void;
  showTeasers?: boolean;
}

export const TeaserListComponent = ({ heading, onTeaserClick, showTeasers = true }: TeaserListProps) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Effect to check the window size and update the state
  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 640); // For example, 640px is your small screen breakpoint
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call at mount to check initial size

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (!showTeasers) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  const teasers = [
    'What is FedRAMP and why is it important?',
    'How often is FedRAMP compliance audited?',
    'What are the key requirements of FedRAMP?',
    'What is the FedRAMP authorization boundary?',
    'What is a FedRAMP ATO and how is it obtained?',
    'How does FedRAMP handle continuous monitoring?',
    'What happens if a CSP fails FedRAMP assessment?',
    'What is a FedRAMP SSP and what should it include?',
    'How do I start the FedRAMP authorization process?',
    'How can a CSP expedite the FedRAMP authorization process?',
    'How does FedRAMP support incident response and management?',
    'What specific controls does FedRAMP require for encryption and data protection?',
    'What is a Third-Party Assessment Organization (3PAO), and what role do they play in FedRAMP?',
    'How does FedRAMP influence the design and architecture of cloud services?',
    'How is FedRAMP authorization different from compliance?',
    'How long does the FedRAMP authorization process take?',
    'What are the costs associated with FedRAMP compliance?',
    'Can existing systems achieve FedRAMP authorization?',
    'How can I ensure my cloud service is FedRAMP compliant?',
    'What is the FedRAMP Marketplace?',
    'What are the benefits of being FedRAMP authorized?',
    'What is the difference between FedRAMP Ready, In Process, and Authorized statuses?',
    'What are the key differences between FedRAMP and other security standards?',
    'How does FedRAMP relate to other compliance frameworks?',
    'What are the best practices for documenting compliance with FedRAMP requirements?',
    'What are the best practices for FedRAMP readiness?',
    'How does FedRAMP benefit end-users of cloud services?',
    'What resources are available for understanding FedRAMP?',
    'What are the key differences between the security control families in SP 800-53?',
    'What are common challenges in achieving FedRAMP authorization?',
  ];

  const displayedTeasers = isSmallScreen ? teasers.slice(0, 10) : teasers;

  return (
    <>
      <div className="flex justify-center flex-col">
        {heading && <h1 className="text-xl font-semibold self-center">{heading}</h1>}
        <div className="flex flex-wrap gap-2 p-2 justify-center max-md:p-1 max-md:gap-1">
          {displayedTeasers.map((teaser, index) => (
            <Badge key={index} className="cursor-pointer" onClick={() => onTeaserClick(teaser)}>
              {teaser}
            </Badge>
          ))}
        </div>
      </div>
    </>
  );
};
// export const TeaserListComponent = ({
//   teasers,
//   heading,
//   actionLabel,
//   alwaysRow = false,
//   clickable = false,
//   onTeaserClick,
//   showTeasers = true,
// }: TeaserListProps) => {
//   console.log('**TeaserListComponent');
//   console.log('**TeaserListComponent teasers', teasers);
//   console.log('**TeaserListComponent heading', heading);
//   console.log('**TeaserListComponent actionLabel', actionLabel);
//   console.log('**TeaserListComponent alwaysRow', alwaysRow);
//   console.log('**TeaserListComponent clickable', clickable);
//   // console.log('**TeaserListComponent onTeaserClick', onTeaserClick);
//   console.log('**TeaserListComponent showTeasers', showTeasers);
//   if (!showTeasers) {
//     // eslint-disable-next-line unicorn/no-null
//     return null;
//   }

//   const renderClickableTeaser = (teaser: Teaser) => (
//     <button
//       className={styles.teaserButton}
//       data-testid="default-question"
//       onClick={() => onTeaserClick && onTeaserClick(teaser.description)}
//       aria-label={actionLabel}
//     >
//       {teaser.description}
//       <span className={styles.teaserClickLabel}>{actionLabel}</span>
//     </button>
//   );

//   return (
//     <div className={styles.teaserListContainer}>
//       {heading && <h1 className={styles.headline}>{heading}</h1>}
//       <ul className={`${styles.teaserList} ${alwaysRow ? styles.alwaysRow : ''}`}>
//         {teasers.map((teaser, index) => (
//           <li key={index} className={styles.teaserListItem}>
//             {clickable ? renderClickableTeaser(teaser) : teaser.description}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

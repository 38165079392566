// import { useState } from 'react';
import type { Citation } from '../../../types.ts';
// import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge.jsx';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/components/ui/tooltip.jsx';
import { SheetTrigger } from '@/components/ui/sheet.jsx';
import * as amplitude from '@amplitude/analytics-browser';

interface CitationListProps {
  label?: string;
  citations?: Citation[];
  selectedCitation?: Citation;
  onCitationClick?: (citation: Citation) => void;
  onTab?: boolean;
}

const compareCitation = (citationA: Citation, citationB: Citation | undefined) => {
  if (citationA && citationB && citationA.text === citationB.text) {
    return true;
  }
  return false;
};

export const CitationList = ({
  label,
  citations,
  selectedCitation,
  onCitationClick,
  onTab = false,
}: CitationListProps) => {
  // const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  if (!citations || citations.length === 0) return;
  console.log('** CitationList', citations);
  return (
    <div className="flex flex-col px-4">
      {label && <p className="text-sm text-muted-foreground">{label}</p>}
      <div className="flex gap-4">
        {citations?.map((citation, index) => (
          <TooltipProvider key={index}>
            <Tooltip>
              <SheetTrigger asChild>
                <TooltipTrigger>
                  <Badge
                    onClick={(event) => {
                      onTab && event.preventDefault();
                      // setSelectedIndex(index);
                      amplitude.track('CITATION', { citation: citation.text });
                      onCitationClick?.(citation);
                    }}
                    // className={`cursor-pointer p-2  rounded`}
                    // className={`w-full text-left cursor-pointer p-2 hover:bg-muted rounded ${compareCitation(citation, selectedCitation) && 'bg-primary'}`}
                    // className="w-full text-left"
                    // className={`cursor-pointer ${selectedIndex === index ? 'bg-secondary' : ''} transition-colors`}
                    className={`cursor-pointer ${onTab && compareCitation(citation, selectedCitation) ? 'bg-secondary' : ''} transition-colors`}
                  >
                    {citation.ref}
                  </Badge>
                </TooltipTrigger>
              </SheetTrigger>
              <TooltipContent>
                {citation.ref}. {citation.text}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
      </div>
      {selectedCitation && onTab && (
        <p>
          {selectedCitation.ref}. {selectedCitation.text}
        </p>
      )}
    </div>
  );
};

// export const CitationList = ({ label, citations, selectedCitation, onCitationClick }: CitationListProps) => {
//   if (!citations || citations.length === 0) return;
//   console.log('** CitationList', citations);
//   return (
//     <div className="flex flex-col px-4">
//       {label && <p className="text-sm text-muted-foreground">{label}</p>}
//       <div className="flex flex-col space-y-2">
//         {citations?.map((citation, index) => (
//           // <li
//           //   key={index}
//           //   className={`p-2 ${compareCitation(citation, selectedCitation) ? 'bg-gray-200' : 'hover:bg-gray-100'} transition-colors duration-150 cursor-pointer`}
//           // >
//           <button
//             key={index}
//             onClick={() => onCitationClick?.(citation)}
//             // className={`cursor-pointer p-2  rounded`}
//             // className={`w-full text-left cursor-pointer p-2 hover:bg-muted rounded ${compareCitation(citation, selectedCitation) && 'bg-primary'}`}
//             // className="w-full text-left"
//             className="cursor-pointer p-2 hover:bg-gray-200 rounded"
//           >
//             {citation.ref}. {citation.text}
//           </button>
//           // </li>
//         ))}
//       </div>
//     </div>
//   );
// };

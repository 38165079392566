import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
// import { createHashRouter, RouterProvider } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import * as amplitude from '@amplitude/analytics-browser';

import './global.css';
// import './index.css';

import Layout from './pages/layout/Layout.jsx';
import Chat from './pages/chat/Chat.jsx';

// Lazy load components
const OneShot = lazy(() => import('./pages/oneshot/OneShot.jsx'));
const Secret = lazy(() => import('./pages/Secret.jsx'));
const NoPage = lazy(() => import('./pages/NoPage.jsx'));

initializeIcons();

amplitude.init('2acbdda0f6bb57bfd0fc57711d0c8e31', {
  defaultTracking: true,
});
// Initialize Amplitude
// if (window.amplitude) {
//   const instance = window.amplitude.getInstance();
//   instance.init('2acbdda0f6bb57bfd0fc57711d0c8e31');
// }

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Chat />,
      },
      {
        path: 'qa',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <OneShot />
          </Suspense>
        ),
      },
      {
        path: 'secret',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Secret />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<div>Page Not Found</div>}>
            <NoPage />
          </Suspense>
        ),
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
// import Layout from './pages/layout/Layout.jsx';
// import Chat from './pages/chat/Chat.jsx';

// initializeIcons();

// const router = createHashRouter([
//   {
//     path: '/',
//     element: <Layout />,
//     children: [
//       {
//         index: true,
//         element: <Chat />,
//       },
//       {
//         path: 'qa',
//         lazy: () => import('./pages/oneshot/OneShot.jsx'),
//       },
//       {
//         path: 'secret',
//         lazy: () => import('./pages/Secret.jsx'),
//       },
//       {
//         path: '*',
//         lazy: () => import('./pages/NoPage.jsx'),
//       },
//     ],
//   },
// ]);

// ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>,
// );

/* eslint-disable unicorn/filename-case */
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import DOMPurify from 'dompurify';
import {
  chatHttpOptions,
  globalConfig,
  // teaserListTexts,
  requestOptions,
  MAX_CHAT_HISTORY,
} from '../../config/global-config.ts';
import type { ChatThreadEntry, Citation, Message, RequestOverrides, TabOptions } from '../../../types.ts';
import { chatEntryToString } from '../../utils/index.ts';
// import { chatEntryToString, newListWithEntryAtIndex } from '../../utils/index.ts';
import useChatController from '../../hooks/use-chat-controller.ts';
import useChatHistory from '../../hooks/use-chat-history.ts';
import { useResizeObserver } from '@wojtekmaj/react-hooks';

import { ChatActionButton } from '../ChatActionButton/index.tsx';
import { ChatFormComponent } from '../ChatFormComponent/index.tsx';
import { ChatStage } from '../ChatStage/index.tsx';
import { ChatThreadComponent } from '../ChatThreadComponent/index.tsx';
import { LoadingIndicator } from '../LoadingIndicator/index.tsx';
import { TeaserListComponent } from '../TeaserListComponent/TeaserListComponent.tsx';
// import { ThoughtProcessPanel } from '../ThoughtProcessPanel/index.tsx';
import { ChatEntryTabContent } from '../ChatEntryTabContent/index.tsx';
// import {VoiceInputButton} from './VoiceInputButton';

// import Owl from '../../assets/Owl.tsx';
// import BrandLogo from '../../assets/BrandLogo.tsx';
// import CancelIcon from '../../assets/CancelIcon.tsx';
import ChevronUpIcon from '../../assets/ChevronUpIcon.tsx';
// import CloseIcon from '../../assets/CloseIcon.tsx';
// import DeleteIcon from '../../assets/DeleteIcon.tsx';
// import HistoryDismissIcon from '../../assets/HistoryDismissIcon.tsx';
// import HistoryIcon from '../../assets/HistoryIcon.tsx';
// import LightbulbIcon from '../../assets/LightbulbIcon.tsx';
// import SendIcon from '../../assets/SendIcon.tsx';

import {
  Sheet,
  SheetContent,
  // SheetDescription,
  // SheetHeader,
  // SheetTitle,
  // SheetTrigger,
} from '@/components/ui/sheet.jsx';
// import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable.jsx';
// import { Button } from '@/components/ui/button.jsx';

import { History, Trash2 } from 'lucide-react';

import styles from './chat-component.module.css';

const useWidth = (target: any) => {
  console.log('** useWidth target', target);
  const [width, setWidth] = useState<number>();

  useLayoutEffect(() => {
    setWidth(target?.current?.getBoundingClientRect()?.width);
  }, [target]);

  useResizeObserver(target?.current, {}, (entry) => {
    console.log('** useWidth entry', entry);
    setWidth(entry[0]?.contentRect?.width);
  });
  // if (!target || !target?.current) return;
  return width || 0;
};

interface ChatComponentProps {
  // inputPosition?: string;
  interactionModel?: 'ask' | 'chat';
  apiUrl?: string;
  useStream?: boolean;
  // isCustomBranding?: boolean;
  overrides?: RequestOverrides;
  approach: string;
  customStyles?: Record<string, string>;
}

export const ChatComponent: React.FC<ChatComponentProps> = ({
  // inputPosition = 'sticky',
  interactionModel = 'chat',
  apiUrl = chatHttpOptions.url,
  useStream = chatHttpOptions.stream,
  // isCustomBranding = globalConfig.IS_CUSTOM_BRANDING,
  approach,
  overrides = {},
  customStyles = {},
}: ChatComponentProps) => {
  console.log('**ChatComponent');
  // const [currentQuestion, setCurrentQuestion] = useState('');
  const [chatThread, setChatThread] = useState<ChatThreadEntry[]>([]);
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [selectedCitation, setSelectedCitation] = useState<Citation>();
  console.log('**ChatComponent selectedCitation', selectedCitation);
  // const [isShowingThoughtProcess, setIsShowingThoughtProcess] = useState(false);
  // const [isProcessingResponse, setIsProcessingResponse] = useState(false);
  // const questionInputRef = useRef(null);

  const [selectedTabId, setSelectedTabId] = useState<TabOptions>('tab-support-context');

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [questionInputValue, setQuestionInputValue] = useState<string>('');
  const [selectedChatEntry, setSelectedChatEntry] = useState<ChatThreadEntry>();

  // const [containerWidth, setContainerWidth] = useState<number>();
  // eslint-disable-next-line unicorn/prevent-abbreviations

  // eslint-disable-next-line unicorn/prevent-abbreviations
  const sheetContentRef = useRef(null);
  // const [sheetWidth, setSheetWidth] = useState(0); // Initial width
  const containerWidth = useWidth(sheetContentRef);
  console.log('**ChatComponent sheetContentRef', sheetContentRef);

  // const [isResetInput, setIsResetInput] = useState<boolean>(false);
  const [isDefaultPromptsEnabled, setIsDefaultPromptsEnabled] = useState<boolean>(
    globalConfig.IS_DEFAULT_PROMPTS_ENABLED && !isChatStarted,
  );

  // Lets try to pass these into the hook instead of having it set them
  const [processingMessage, setProcessingMessage] = useState<ChatThreadEntry | undefined>();
  // Custom hooks from ChatController and ChatHistoryController
  const { clearChat, isProcessingResponse, generateAnswer, cancelRequest } = useChatController(
    apiUrl,
    useStream,
    processingMessage,
    setProcessingMessage,
  );
  const { chatHistory, showChatHistory, toggleChatHistory, saveChatHistory } = useChatHistory();

  // const style = document.documentElement.style;
  {
    console.log('ChatComponent customStyles', customStyles);
  }
  // useEffect(() => {
  //   console.log('ChatComponent customStyles useEffect');
  //   if (customStyles) {
  //     // style.setProperty('--c-accent-high', customStyles.AccentHigh);
  //     // style.setProperty('--c-accent-lighter', customStyles.AccentLight);
  //     // style.setProperty('--c-accent-dark', customStyles.AccentDark);
  //     // style.setProperty('--c-text-color', customStyles.TextColor);
  //     // style.setProperty('--c-light-gray', customStyles.BackgroundColor);
  //     // style.setProperty('--c-dark-gray', customStyles.ForegroundColor);
  //     // style.setProperty('--c-base-gray', customStyles.FormBackgroundColor);
  //     // style.setProperty('--radius-base', customStyles.BorderRadius);
  //     // style.setProperty('--border-base', customStyles.BorderWidth);
  //     // style.setProperty('--font-base', customStyles.FontBaseSize);
  //   }
  // }, [customStyles, style]);

  // useEffect(() => {
  //   console.log('ChatComponent setIsResetInput useEffect');

  //   setIsResetInput(!!questionInputValue);
  // }, [isProcessingResponse, questionInputValue]);

  // useEffect(() => {

  // }, [isProcessingResponse]);

  // eslint-disable-next-line unicorn/no-null
  const [lastProcessedMessageId, setLastProcessedMessageId] = useState<string>();
  useEffect(() => {
    console.log('***processingMessage', processingMessage);
    if (processingMessage && processingMessage.id !== lastProcessedMessageId) {
      const index = chatThread.findIndex((chat) => chat.id === processingMessage.id);
      setChatThread((currentThread) =>
        index >= 0
          ? currentThread.map((chat, i) => (i === index ? processingMessage : chat))
          : [...currentThread, processingMessage],
      );
      setLastProcessedMessageId(processingMessage.id);
    }
  }, [processingMessage, lastProcessedMessageId, chatThread]);
  // useEffect(() => {
  //   console.log('ChatComponent processingMessage useEffect');
  //   // console.log('ChatComponent isProcessingResponse useEffect', isProcessingResponse);
  //   console.log('processingMessage', processingMessage);
  //   console.log('chatThread', chatThread);
  //   if (!processingMessage) return;

  //   // Check if this is an update to an existing message
  //   const index = chatThread.findIndex((chat) => chat.id === processingMessage.id);
  //   if (index > -1) {
  //     // Update existing message
  //     setChatThread((currentThread) => currentThread.map((chat, i) => (i === index ? processingMessage : chat)));
  //   } else {
  //     // Append new message
  //     setChatThread((currentThread) => [...currentThread, processingMessage]);
  //   }
  // }, [chatThread, processingMessage]);
  // useEffect(() => {
  //   console.log('ChatComponent processingMessage useEffect');
  //   console.log('ChatComponent isProcessingResponse useEffect', isProcessingResponse);
  //   console.log('processingMessage', processingMessage);
  //   console.log('chatThread', chatThread);
  //   setIsDisabled(isProcessingResponse);

  //   // if (processingMessage && isProcessingResponse) {
  //   if (processingMessage && isProcessingResponse) {
  //     const processingEntry = processingMessage;
  //     const index = chatThread.findIndex((entry) => entry.id === processingEntry.id);
  //     console.log('********useEffect index:', index);

  //     console.log('********Before setChatThread update:', chatThread);
  //     setChatThread((previousChatThread) =>
  //       index > -1
  //         ? newListWithEntryAtIndex(previousChatThread, index, processingEntry)
  //         : [...previousChatThread, processingEntry],
  //     );
  //     console.log('********After setChatThread update:', chatThread);
  //   }
  // }, [chatThread, processingMessage, isProcessingResponse]);

  // useEffect(() => {
  //   // Apply custom styles dynamically
  //   for (const [key, value] of Object.entries(customStyles)) {
  //     document.documentElement.style.setProperty(`--${key}`, value);
  //   }
  // }, [customStyles]);

  // const handleVoiceInput = (inputText: string) => {
  //   const sanitizedValue = DOMPurify.sanitize(inputText);
  //   setQuestionInputValue(sanitizedValue);
  // };

  const handleQuestionInputClick = (question: string) => {
    const sanitizedValue = DOMPurify.sanitize(question);
    setQuestionInputValue(sanitizedValue);
  };

  const handleCitationClick = (citation: Citation, chatThreadEntry?: ChatThreadEntry) => {
    setSelectedCitation(citation);
    if (chatThreadEntry) {
      setSelectedChatEntry(chatThreadEntry);
    }
    setSelectedTabId('tab-citations');

    // if (!isShowingThoughtProcess) {
    //   if (chatThreadEntry) {
    //     setSelectedChatEntry(chatThreadEntry);
    //   }
    //   // handleExpandAside();
    // }
  };

  // ADD THIS ONE IN
  const getMessageContext = () => {
    if (interactionModel === 'ask') {
      return [];
    }

    const history = [
      ...chatThread,
      // include the history from the previous session if the user has enabled the chat history
      ...(showChatHistory ? chatHistory : []),
    ];

    const messages: Message[] = history.map((entry) => {
      return {
        content: chatEntryToString(entry),
        role: entry.isUserMessage ? 'user' : 'assistant',
      };
    });

    return messages;
  };

  const handleUserChatSubmit = async (inputQuestion: string) => {
    console.log('**handleUserChatSubmit');
    // collapseAside();
    const question: string = DOMPurify.sanitize(inputQuestion);
    setIsChatStarted(true);
    setIsDefaultPromptsEnabled(false);
    // Use the generateAnswer function provided by the hook
    // generateAnswer({ question: sanitizedQuestion }, { ...requestOptions, overrides });
    await generateAnswer(
      {
        ...requestOptions,
        approach,
        overrides: {
          ...requestOptions.overrides,
          ...overrides,
        },
        question,
        type: interactionModel,
        messages: getMessageContext(),
      },
      {
        // use defaults
        ...chatHttpOptions,

        // override if the user has provided different values
        url: apiUrl,
        stream: useStream,
      },
    );

    if (interactionModel === 'chat') {
      saveChatHistory(chatThread);
    }

    setQuestionInputValue('');
    // setIsResetInput(false);
  };

  // Reset the input field and the current question
  // const resetInputField = () => {
  //   setQuestionInputValue('');
  //   // setCurrentQuestion('');
  //   setIsResetInput(false);
  // };

  // Reset the chat and show the default prompts
  const resetCurrentChat = () => {
    setIsChatStarted(false);
    setChatThread([]);
    setIsDisabled(false);
    setIsDefaultPromptsEnabled(true);
    setSelectedCitation(undefined);
    clearChat();
    // clean up the current session content from the history too
    saveChatHistory(chatThread);
    // collapseAside();
    // handleUserChatCancel();
    cancelRequest();
  };

  // Show the default prompts when enabled
  const showDefaultPrompts = () => {
    if (!isDefaultPromptsEnabled) {
      resetCurrentChat();
    }
  };

  // show thought process aside
  // const handleExpandAside = () => {
  //   setIsShowingThoughtProcess(true);
  //   setSelectedTabId('tab-thought-process');
  // };

  // hide thought process aside
  // const collapseAside = () => {
  //   setIsShowingThoughtProcess(false);
  //   setSelectedCitation(undefined);
  // };

  const handleChatEntryActionButtonClick = (entry: ChatThreadEntry) => {
    setSelectedChatEntry(entry);
    setSelectedTabId('tab-support-context');
    // handleExpandAside();
  };

  return (
    <>
      <Sheet>
        {/* <div
          id="overlay"
          className={`${styles.overlay} ${isShowingThoughtProcess ? `${styles.overlayActive}` : ''}`}
        ></div> */}
        <section
          id="chat__containerWrapper"
          className={`${styles.chatContainerWrapper}`}
          // className={`${styles.chatContainerWrapper} ${isShowingThoughtProcess ? `${styles.asideOpen}` : ''}`}
        >
          {!isChatStarted && (
            <ChatStage
              pageTitle="FedOwl"
              // pageTitle={globalConfig.BRANDING_HEADLINE as string}
              // url="/"
              // url={globalConfig.BRANDING_URL as string}
              // SvgIcon={Owl}
              // SvgIcon={BrandLogo}
            />
          )}

          <section className={styles.chatContainer} id="chat-container">
            {isChatStarted === true ? (
              <>
                <div className={styles.chatHeaderThread}>
                  {/* {interactionModel === 'chat' ? chatHistoryController.renderHistoryButton({ disabled: isDisabled }) : ''} */}
                  {interactionModel === 'chat' ? (
                    // <SheetTrigger>
                    <ChatActionButton
                      tooltip={
                        showChatHistory ? globalConfig.HIDE_CHAT_HISTORY_LABEL : globalConfig.SHOW_CHAT_HISTORY_LABEL
                      }
                      actionId="chat-history-button"
                      onClick={toggleChatHistory}
                      isDisabled={false} // Add your logic here if needed
                      SvgIcon={History}
                      // SvgIcon={showChatHistory ? HistoryDismissIcon : HistoryIcon}
                    />
                  ) : (
                    // </SheetTrigger>
                    ''
                  )}

                  <ChatActionButton
                    tooltip={globalConfig.RESET_CHAT_BUTTON_TITLE}
                    actionId="chat-reset-button"
                    onClick={() => resetCurrentChat()}
                    SvgIcon={Trash2}
                    // SvgIcon={DeleteIcon}
                  />
                </div>

                {showChatHistory === true ? (
                  <div className={styles.chatHistoryContainer}>
                    <ChatThreadComponent
                      chatThread={chatHistory}
                      // actionButtons={[
                      //   {
                      //     actionId: 'chat-show-thought-process',
                      //     tooltip: globalConfig.SHOW_THOUGH_PROCESS_BUTTON_LABEL_TEXT,
                      //     SvgIcon: Lightbulb,
                      //     // SvgIcon: LightbulbIcon,
                      //     isDisabled: isShowingThoughtProcess,
                      //     onClick: () => handleChatEntryActionButtonClick,
                      //   },
                      // ]}
                      // isShowingThoughtProcess={isShowingThoughtProcess}
                      isDisabled={isDisabled}
                      isProcessingResponse={isProcessingResponse}
                      selectedCitation={selectedCitation}
                      handleChatEntryActionButtonClick={handleChatEntryActionButtonClick}
                      onCitationClick={handleCitationClick}
                      onFollowupQuestionClick={handleQuestionInputClick}
                    />

                    <div className={styles.chatHistoryFooter}>
                      <ChevronUpIcon /> {/* TODO: not sure what this is */}
                      {globalConfig.CHAT_HISTORY_FOOTER_TEXT.replace(
                        globalConfig.CHAT_MAX_COUNT_TAG,
                        String(MAX_CHAT_HISTORY),
                      )}
                      <ChevronUpIcon />
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <ChatThreadComponent
                  chatThread={chatThread}
                  // actionButtons={[
                  //   {
                  //     actionId: 'chat-show-thought-process',
                  //     tooltip: globalConfig.SHOW_THOUGH_PROCESS_BUTTON_LABEL_TEXT,
                  //     SvgIcon: Lightbulb,
                  //     // SvgIcon: LightbulbIcon,
                  //     isDisabled: isShowingThoughtProcess,
                  //     onClick: () => {},
                  //   },
                  // ]}
                  // isShowingThoughtProcess={isShowingThoughtProcess}
                  isDisabled={isDisabled}
                  isProcessingResponse={isProcessingResponse}
                  selectedCitation={selectedCitation}
                  handleChatEntryActionButtonClick={handleChatEntryActionButtonClick}
                  onCitationClick={handleCitationClick}
                  onFollowupQuestionClick={handleQuestionInputClick}
                />
              </>
            ) : (
              ''
            )}

            {/* Loading Indicator */}
            {isProcessingResponse && <LoadingIndicator label={globalConfig.LOADING_INDICATOR_TEXT} />}

            {/* <!-- Teaser List with Default Prompts --> */}
            <div className={styles.chatContainer}>
              <TeaserListComponent
                heading="Ask anything about FedRAMP"
                // heading={interactionModel === 'chat' ? teaserListTexts.HEADING_CHAT : teaserListTexts.HEADING_ASK}
                // clickable={true}
                // actionLabel={teaserListTexts.TEASER_CTA_LABEL}
                onTeaserClick={handleQuestionInputClick}
                // teasers={teaserListTexts.DEFAULT_PROMPTS}
                showTeasers={isDefaultPromptsEnabled}
              />
            </div>
            <ChatFormComponent
              // inputPosition={inputPosition}
              isDisabled={isDisabled}
              // isResetInput={isResetInput}
              isProcessingResponse={isProcessingResponse}
              isDefaultPromptsEnabled={isDefaultPromptsEnabled}
              onUserChatSubmit={handleUserChatSubmit}
              cancelRequest={cancelRequest}
              // handleVoiceInput={handleVoiceInput}
              // resetInputField={resetInputField}
              showDefaultPrompts={showDefaultPrompts}
              questionInputValue={questionInputValue}
              setQuestionInputValue={setQuestionInputValue}
            />
          </section>
          {/* <SheetContent className="md:max-w-fit md:min-w-8 w-11/12 max-h-90" style={{ width: containerWidth }}> */}
          {/* <SheetContent ref={sheetContentRef} className="w-11/12 max-h-90" style={{ width: `${containerWidth + 16}px` }}> */}
          {/* <ResizablePanelGroup direction="horizontal">
            <ResizableHandle
              onResize={(newWidth) => {
                console.log('**ResizableHandle onResize', newWidth);
                setSheetWidth(500);
                // setSheetWidth(newWidth)
              }}
            />
            <ResizablePanel
              style={{
                width: `${sheetWidth}px`,
                maxWidth: '80%',
                minWidth: '3px', // Minimum width
                position: 'fixed',
                right: 0,
                top: 0,
                bottom: 0,
              }}
            >
              <ResizableHandle
                onResize={(newWidth) => {
                  console.log('**ResizableHandle onResize', newWidth);
                  setSheetWidth(500);
                  // setSheetWidth(newWidth)
                }}
              /> */}
          <SheetContent ref={sheetContentRef} className="overflow-auto max-h-90" style={{ width: '90%' }}>
            {/* <ResizableHandle
              onResize={(newWidth) => {
                console.log('**ResizableHandle onResize', newWidth);
                setSheetWidth(500);
                // setSheetWidth(newWidth)
              }}
            /> */}
            <ChatEntryTabContent
              // isShowingThoughtProcess={isShowingThoughtProcess}
              // collapseAside={() => setIsShowingThoughtProcess(false)}
              selectedChatEntry={selectedChatEntry}
              selectedCitation={selectedCitation}
              onCitationClick={handleCitationClick}
              selectedTabId={selectedTabId}
              setSelectedTabId={setSelectedTabId}
              apiUrl={apiUrl}
              containerWidth={containerWidth}
              // containerWidth={sheetWidth || 500}
              // setContainerWidth={setContainerWidth}
              // sheetContentRef={sheetContentRef.current}
            />
          </SheetContent>
          {/* <ResizableHandle
                onResize={(newWidth) => {
                  console.log('**ResizableHandle onResize', newWidth);
                  setSheetWidth(500);
                  // setSheetWidth(newWidth)
                }}
              />
            </ResizablePanel>
          </ResizablePanelGroup> */}
        </section>
      </Sheet>
    </>
  );
};

import React from 'react';
import DOMPurify from 'dompurify';

// import { VoiceInputButton } from '../VoiceInputButton/index.js';

import { globalConfig } from '../../config/global-config.js';
import { useEnterSubmit } from '../../hooks/use-enter-submit.tsx';

// import { Badge } from "@/components/ui/badge.jsx"
import { Button } from '@/components/ui/button.jsx';
// import {
//   Drawer,
//   DrawerContent,
//   DrawerDescription,
//   DrawerHeader,
//   DrawerTitle,
//   DrawerTrigger,
// } from "@/components/ui/drawer.jsx"
// import { Input } from "@/components/ui/input.jsx"
import { Label } from '@/components/ui/label.jsx';
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "@/components/ui/select.jsx"
// import { Textarea } from '@/components/ui/textarea.jsx';
import TextareaAutosize from 'react-textarea-autosize';
// import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/components/ui/tooltip.jsx';

import {
  // Bird,
  // Book,
  // Bot,
  // Code2,
  // CornerDownLeft,
  // LifeBuoy,
  // Mic,
  // Paperclip,
  // Rabbit,
  // Settings,
  // Settings2,
  // Share,
  // SquareTerminal,
  // SquareUser,
  // Triangle,
  // Turtle,
  Send,
  X,
} from 'lucide-react';
import * as amplitude from '@amplitude/analytics-browser';

// import styles from './chat-form-component.module.css';

interface ChatFormComponentProps {
  // inputPosition?: string;
  isDisabled: boolean;
  // isResetInput: boolean;
  isProcessingResponse: boolean;
  isDefaultPromptsEnabled: boolean;
  onUserChatSubmit: (question: string) => void;
  cancelRequest: () => void;
  // handleVoiceInput: (input: string) => void;
  // resetInputField: () => void;
  showDefaultPrompts: () => void;
  questionInputValue: string;
  setQuestionInputValue: (input: string) => void;
}

export const ChatFormComponent = ({
  // inputPosition = 'sticky',
  isDisabled,
  // isResetInput,
  isProcessingResponse,
  isDefaultPromptsEnabled,
  onUserChatSubmit,
  cancelRequest,
  // handleVoiceInput,
  // resetInputField,
  showDefaultPrompts,
  questionInputValue,
  setQuestionInputValue,
}: ChatFormComponentProps) => {
  const { formRef, onKeyDown } = useEnterSubmit();
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = DOMPurify.sanitize(event.target.value);
    setQuestionInputValue(inputValue);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onUserChatSubmit(questionInputValue);
    // if (window.amplitude) {
    console.log('Logging event: CHAT_SUBMIT', { question: questionInputValue });
    amplitude.track('CHAT_SUBMIT', { question: questionInputValue });
    // }
    setQuestionInputValue(''); // Optionally clear input after submission
  };

  // Assuming VoiceInputButton component can call this prop with the voice input text
  // const onVoiceInputReceived = (input: string) => {
  //   const sanitizedInput = DOMPurify.sanitize(input);
  //   setQuestionInputValue(sanitizedInput);
  //   handleVoiceInput(sanitizedInput); // If you need to do additional processing on voice input
  // };

  return (
    <div className="flex flex-col">
      <form
        id="chat-form"
        onSubmit={handleSubmit}
        ref={formRef}
        // className={`${styles.formContainer} ${inputPosition === 'sticky' ? styles.formContainerSticky : ''}`}
        // className="overflow-hidden rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring fixed bottom-0 ml-2 mb-4 w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 2xl:w-7/12 flex justify-between items-center"
        // className="overflow-hidden rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring fixed bottom-4 left-16 right-16 w-full flex justify-between items-center"
        className="overflow-hidden rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring self-end w-full flex justify-between items-center"
      >
        <Label htmlFor="message" className="sr-only">
          Message
        </Label>
        <TextareaAutosize
          // id="message"
          data-testid="question-input"
          id="question-input"
          // placeholder="Type your message here..."
          placeholder={globalConfig.CHAT_INPUT_PLACEHOLDER}
          className="flex border border-input bg-background px-3 py-4 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 min-h-9 resize-none p-3bg-transparent outline-none border-none hover:border-none focus:border-none focus:ring-0 focus-visible:ring-0 rounded-none w-full flex-1"
          value={questionInputValue}
          disabled={isDisabled}
          autoComplete="off"
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
        />
        {/* <div className="flex items-center p-3 pt-0"> */}
        {isProcessingResponse ? (
          <Button
            type="submit"
            size="sm"
            className="ml-auto gap-1.5 mr-3"
            data-testid="cancel-question-button"
            onClick={cancelRequest}
          >
            <X className="size-3.5" />
          </Button>
        ) : (
          <Button
            type="submit"
            size="icon"
            className="ml-auto gap-1.5 mr-3 hover:bg-blue-700 cursor-pointer"
            data-testid="submit-question-button"
            disabled={isDisabled}
            // variant="outline"
          >
            <Send className="size-3.5" />
          </Button>
        )}
        {/* {isResetInput ? (
            <Button
              title={globalConfig.RESET_BUTTON_TITLE_TEXT}
              // className={styles.chatboxButtonReset}
              // hidden={!isResetInput}
              type="reset"
              id="resetBtn"
              // title="Clear input"
              onClick={() => resetInputField()}
            >
              {globalConfig.RESET_BUTTON_LABEL_TEXT}
            </Button>
          ) : (
            ''
          )} 
        </div>*/}
      </form>

      {isDefaultPromptsEnabled ? (
        ''
      ) : (
        <div className="">
          <Button type="button" variant="link" onClick={() => showDefaultPrompts()} className="cursor-pointer">
            {globalConfig.DISPLAY_DEFAULT_PROMPTS_BUTTON}
          </Button>
        </div>
      )}
    </div>
  );
  // return (
  //   <form
  //     id="chat-form"
  //     onSubmit={handleSubmit}
  //     className={`${styles.formContainer} ${inputPosition === 'sticky' ? styles.formContainerSticky : ''}`}
  //   >
  //     <div className={styles.ChatboxContainer}>
  //       <div className={styles.chatboxInputContainer}>
  //         <input
  //           className={styles.chatboxInput}
  //           data-testid="question-input"
  //           id="question-input"
  //           placeholder={globalConfig.CHAT_INPUT_PLACEHOLDER}
  //           aria-labelledby="chatbox-label"
  //           name="chatbox"
  //           type="text"
  //           value={questionInputValue}
  //           disabled={isDisabled}
  //           autoComplete="off"
  //           onChange={handleInputChange}
  //         />
  //         {isResetInput ? '' : <VoiceInputButton onVoiceInput={onVoiceInputReceived} />}
  //       </div>
  //       {isProcessingResponse ? (
  //         <button
  //           className={styles.chatboxButton}
  //           data-testid="cancel-question-button"
  //           onClick={cancelRequest}
  //           title={globalConfig.CHAT_CANCEL_BUTTON_LABEL_TEXT}
  //         >
  //           <CancelIcon />
  //         </button>
  //       ) : (
  //         <button
  //           className={styles.chatboxButton}
  //           data-testid="submit-question-button"
  //           // onClick={handleUserChatSubmit}
  //           title={globalConfig.CHAT_BUTTON_LABEL_TEXT}
  //           disabled={isDisabled}
  //           type="submit"
  //         >
  //           <SendIcon />
  //         </button>
  //       )}
  //       {isResetInput ? (
  //         <button
  //           title={globalConfig.RESET_BUTTON_TITLE_TEXT}
  //           className={styles.chatboxButtonReset}
  //           // hidden={!isResetInput}
  //           type="reset"
  //           id="resetBtn"
  //           // title="Clear input"
  //           onClick={() => resetInputField()}
  //         >
  //           {globalConfig.RESET_BUTTON_LABEL_TEXT}
  //         </button>
  //       ) : (
  //         ''
  //       )}
  //     </div>

  //     {isDefaultPromptsEnabled ? (
  //       ''
  //     ) : (
  //       <div className="chat__containerFooter">
  //         <button type="button" onClick={() => showDefaultPrompts()} className={styles.footerButton}>
  //           {globalConfig.DISPLAY_DEFAULT_PROMPTS_BUTTON}
  //         </button>
  //       </div>
  //     )}
  //   </form>
  // );
};

// ThemeSwitch.tsx
import React from 'react';
import { Toggle } from '@fluentui/react';
import './ThemeSwitch.css';

interface ThemeSwitchProps {
  onToggle: (isDarkTheme: boolean) => void;
  isDarkTheme: boolean;
  // isConfigPanelOpen: boolean;
}

export const ThemeSwitch: React.FC<ThemeSwitchProps> = ({ onToggle, isDarkTheme }) => {
  return (
    <div className="ms-toggle-wrapper">
      <Toggle
        label="Select theme"
        onText="Dark Theme"
        offText="Light Theme"
        checked={isDarkTheme}
        onChange={() => onToggle(!isDarkTheme)}
      />
    </div>
  );
};
